import React, { Component, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDatatable from '../../utils/ReactDatatable';
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CsvUploadModal from "../partials/CsvUploadModal";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { Collapse } from 'react-collapse';
import DatePicker from "react-datepicker";
import moment from "moment"
import { Link } from "react-router-dom";

import dashboard from "../../utils/dashboard.png"
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
class Reports extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "rx_group",
                text: "Rx Group",
                className: "rx_group",
                align: "left",
                sortable: true,
            },
            {
                key: "group_name",
                text: "Group Name",
                className: "group_name",
                align: "left",
                sortable: true,
            },
            {
                key: "member_id",
                text: "Member ID",
                className: "member_id",
                align: "left",
                sortable: true
            },

            {
                key: "card_created",
                text: "Card created",
                className: "card_created",
                align: "left",
                sortable: true,
            },
            {
                key: "date_written",
                text: "Data Written",
                className: "date_written",
                align: "left",
                sortable: true
            },
            {
                key: "date_submitted",
                text: "Date Submitted",
                className: "date_submitted",

                align: "left",
                sortable: true
            },
            {
                key: "date_reversed",
                text: "Date Reversed",
                className: "date_reversed",
                align: "left",
                sortable: true
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
            },
            {
                key: "rx_number",
                text: "Rx Number",
                className: "rx_number",
                align: "left",
                sortable: true
            },
            {
                key: "refill",
                text: "Refill",
                className: "refill",
                align: "left",
                sortable: true,
            },
            {
                key: "prescription_dea",
                text: "Prescriper DEA",
                className: "prescription_dea",
                align: "left",
                sortable: true,
            },


            {
                key: "prescription_npi",
                text: "Prescriber NPI",
                className: "prescription_npi",
                align: "left",
                sortable: true,
            },
            {
                key: "ndc",
                text: "NDC",
                className: "ndc",
                align: "left",
                sortable: true
            },
            {
                key: "mdeication_label",
                text: "Medication Label Name",
                className: "mdeication_label",
                align: "left",
                sortable: true,
            },
            {
                key: "type",
                text: "Type",
                className: "type",
                align: "left",
                sortable: true,
            },
            {
                key: "qty",
                text: "Qty",
                className: "qty",
                align: "left",
                sortable: true,
            },
            {
                key: "day_supply",
                text: "Day supply",
                className: "day_supply",
                align: "left",
                sortable: true,
            },


            {
                key: "cardholder_age",
                text: "Cardholder Age",
                className: "cardholder_age",
                align: "left",
                sortable: true
            },
            {
                key: "unc",
                text: "Unc",
                className: "unc",
                align: "left",
                sortable: true,

            },
            {
                key: "naf",
                text: "Naf",
                className: "naf",
                align: "left",
                sortable: true,
            },
            {
                key: "payable",
                text: "Payable",
                className: "payable",
                align: "left",
                sortable: true,
            },
            {
                key: "retail_price",
                text: "Retail Price",
                className: "retail_price",
                align: "left",
                sortable: true
            },
            {
                key: "member_paid",
                text: "Member Paid",
                className: "member_paid",
                align: "left",
                sortable: true
            },
            {
                key: "saving_dollar",
                text: "Saving Dollar",
                className: "saving_dollar",
                align: "left",
                sortable: true,
                currency: true
            },
            {
                key: "saving_percent",
                text: "Saving Percent",
                className: "saving_percent",
                align: "left",
                sortable: true,
            },
            {
                key: "pharmacy_npi",
                text: "Pharmacy NPI",
                className: "pharmacy_npi",
                align: "left",
                sortable: true
            },
            {
                key: "pharmacy_name",
                text: "Pharmacy Name",
                className: "pharmacy_name",
                align: "left",
                sortable: true
            }

        ];

        if (this.props.auth.user.superadmin) {
            this.columns.push(
                {

                    key: "address",
                    text: "Address",
                    className: "address",
                    align: "left",
                    sortable: true,
                },
                {
                    key: "city",
                    text: "City",
                    className: "city",
                    align: "left",
                    sortable: true,
                },
                {
                    key: "state",
                    text: "State",
                    className: "state",
                    align: "left",
                    sortable: true,
                },
                {
                    key: "zip",
                    text: "Zip",
                    className: "zip",
                    align: "left",
                    sortable: true,
                }
            )
        }
        if (this.props.auth.user.superadmin) {
            this.columns.push({
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            {/* <button
                                data-toggle="modal"
                                data-target="#update-user-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{ marginRight: '5px' }}>
                                <i className="fa fa-edit"></i>
                            </button> */}
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            })

        }

        this.config = {
            page_size: 20,
            length_menu: [20, 50, 100],
            filename: "Records",
            no_data_text: 'No record found!',
            button: {
                excel: false,
                print: false,
                csv: true,
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: false,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            toggleNav: true,
            records: [],
            totalCount: 0,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "asc" }
            },
            currentRecord: {
            },
            rx_group: undefined,
            group_name: undefined,
            member_id: undefined,
            status: undefined,
            prescription_npi: undefined,
            mdeication_label: undefined,
            ndc: undefined,
            type: undefined,
            loading: false,
            isFilterOpen: true,
            from_date: moment().subtract(90, "days").startOf('day').toDate(),
            to_date: moment().endOf('day').toDate(),
            rx_group_associated: undefined
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {

        this.getData()
    };

    getData() {
        this.setState({ loading: true })
        let filterData = this.state.filterData
        filterData.search = {
            ...(this.state.ndc !== '' && { ndc: this.state.ndc }),
            ...(this.state.group_name !== '' && { group_name: this.state.group_name }),
            ...(this.state.member_id !== '' && { member_id: this.state.member_id }),
            ...(this.state.status !== '' && { status: this.state.status }),
            ...(this.state.prescription_npi !== '' && { prescription_npi: this.state.prescription_npi }),
            ...(this.state.mdeication_label !== '' && { mdeication_label: this.state.mdeication_label }),
            ...(this.state.type !== '' && { type: this.state.type }),
            ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            ...(this.state.rx_group_associated !== '' && { rx_group_associated: this.state.rx_group_associated })
        }
        axios
            .post("/api/reports", filterData)
            .then(res => {
                let result = []
                for (let i = 0; i < res.data.records.length; i++) {
                    let each = res.data.records[i]
                    if (each.pharmacy_name !== "") {
                        each.retail_price = each.retail_price ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.retail_price) : ""
                        each.member_paid = each.member_paid ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.member_paid) : ""
                        each.saving_dollar = each.saving_dollar ? Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(each.saving_dollar) : ""
                        each.date_written = each.date_written ? moment(each.date_written).add(5, 'hours').format('L') : ""
                        each.date_submitted = each.date_submitted ? moment(each.date_submitted).add(5, 'hours').format('L') : ""
                        each.card_created = each.card_created ? moment(each.card_created).add(5, 'hours').format('L') : ""
                        each.date_reversed = each.date_reversed ? moment(each.date_reversed).add(5, 'hours').format('L') : ""
                        each.saving_percent = each.saving_percent + "%"
                        result.push(each)
                    }

                }
                this.setState({
                    records: res.data.records,
                    totalCount: res.data.count,
                    loading: false

                })

            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
    }


    editRecord(record) {
        this.setState({ currentRecord: record });
    }
    deleteRecord(record) {
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.finalDelete(record)
                },
                {
                    label: 'No',
                    onClick: () => { }
                }
            ]
        });

    }

    finalDelete = (record) => {
        axios
            .post("/api/reports-delete",
                {
                    _id: record._id,
                    // delete: this.props.auth.user.user_type!=="Technician"?true:false,
                    // email: this.props.auth.user.email
                }
            )
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            });
        this.getData();
    }
    filter = (filterData) => {
        this.setState({ filterData }, () => this.getData())
    }

    onChange = e => {
        this.setState({ [e.target.id]: e.target.value });
    };
    setFromDate = (date) => {
        this.setState({ from_date: date });
    }
    setToDate = (date) => {
        this.setState({ to_date: date });
    }

    onSearch = e => {
        e.preventDefault();
        this.getData();
    }

    onChangeSelect = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    navBar = () => {
        this.setState({ toggleNav: !this.state.toggleNav })
    }

    resetSearch = () => {
        this.setState({
            ndc: undefined,
            group_name: undefined,
            member_id: undefined,
            status: undefined,
            from_date: undefined,
            to_date: undefined,
            prescription_npi: undefined,
            mdeication_label: undefined,
            type: undefined,
            rx_group_associated: undefined,
            filterData: {
                page_number: 1,
                page_size: 20,
                sort_order: { column: "_id", order: "asc" }
            }
        }, () => this.getData())
    }

    toggleChange = () => {
        this.setState({
            isFilterOpen: !this.state.isFilterOpen,
        });
    }

    exportToCSV = (from, to) => {
        let filterData = {}
        this.setState({ loading: true })
        filterData.search = {
            ...(this.state.ndc !== '' && { ndc: this.state.ndc }),
            ...(this.state.group_name !== '' && { group_name: this.state.group_name }),
            ...(this.state.member_id !== '' && { member_id: this.state.member_id }),
            ...(this.state.status !== '' && { status: this.state.status }),
            ...(this.state.prescription_npi !== '' && { prescription_npi: this.state.prescription_npi }),
            ...(this.state.mdeication_label !== '' && { mdeication_label: this.state.mdeication_label }),
            ...(this.state.type !== '' && { type: this.state.type }),
            ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            ...(this.state.rx_group_associated !== '' && { rx_group_associated: this.state.rx_group_associated })
        }
        axios
            .post("/api/export-reports", filterData)
            .then(res => {
                toast.success('Dowloading...Please Wait!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                var headers = {
                    rx_group: "Rx Group",
                    group_name: "Group Name",
                    member_id: "Member Id",
                    card_created: "Card Created",
                    date_written: "Date Written",
                    date_submitted: "Date Submitted",
                    date_reversed: "Date Reversed",
                    status: "Status",
                    rx_number: "Rx Number",
                    refill: "Refill",
                    prescription_dea: "Prescription DEA",
                    prescription_npi: "Prescription NPI",
                    ndc: "NDC",
                    mdeication_label: "Mdeication Label",
                    type: "Type",
                    qty: "Qty",
                    day_supply: "Day Supply",
                    cardholder_age: "Cardholder Age",
                    unc: "UNC",
                    naf: "NAF",
                    payable: "Payable",
                    retail_price: "Retail Price",
                    member_paid: "Member Paid",
                    saving_dollar: "Saving Dollar",
                    saving_percent: "Saving Percent",
                    pharmacy_npi: "Pharmacy NPI",
                    pharmacy_name: "Pharmacy Name",
                    address: "Address",
                    city: "City",
                    state: "State",
                    zip: "Zip",
                };
                // // add columns in sheet array
                // for (let column of this.props.columns) {
                //     headers[column.key] = '"' + column.text + '"';
                // }
                var records = [];
                // add data rows in sheet array
                this.setState({ loading: false })
                for (let i in res.data.records) {
                    let record = res.data.records[i]
                    delete record._id; delete record.id; delete record.__v
                    record.mdeication_label = '"' + record.mdeication_label + '"'
                    if (record.date_written !== '') {
                        record.date_written = moment(record.date_written).format('L');
                    }
                    if (record.date_submitted !== '') {
                        record.date_submitted = moment(record.date_submitted).format('L');
                    }
                    if (record.card_created !== '') {
                        record.card_created = moment(record.card_created).format('L');
                    }
                    if (record.date_reversed !== null) {
                        record.date_reversed = moment(record.date_reversed).format('L');
                    }
                    else {
                        record.date_reversed = ''
                    }
                    records.push(record);
                }
                if (headers) {
                    records.unshift(headers);
                }
                // Convert Object to JSON
                let jsonObject = JSON.stringify(records);
                let csv = this.convertToCSV(jsonObject);
                let exportedFilenmae = this.config.filename + '.csv' || 'export.csv';
                let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilenmae);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) { // feature detection
                        // Browsers that support HTML5 download attribute
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilenmae);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch( (error) => {
                this.setState({
                    loading: false
                })
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })


    }

    convertToCSV(objArray) {
        let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        for (let i = 0; i < array.length; i++) {
            let line = '';
            for (let index in array[i]) {
                if (line != '') line += ','
                line += array[i][index];
            }
            str += line + '\r\n';
        }
        return str;
    }

    exportToPDF = (from, to) => {
        let filterData = {}
        this.setState({ loading: true })
        filterData.search = {
            ...(this.state.rx_group !== '' && { name: this.state.rx_group }),
            ...(this.state.group_name !== '' && { group_name: this.state.group_name }),
            ...(this.state.member_id !== '' && { member_id: this.state.member_id }),
            ...(this.state.status !== '' && { status: this.state.status }),
            ...(this.state.prescription_npi !== '' && { prescription_npi: this.state.prescription_npi }),
            ...(this.state.mdeication_label !== '' && { mdeication_label: this.state.mdeication_label }),
            ...(this.state.type !== '' && { type: this.state.type }),
            ...(this.state.from_date !== '' && { from_date: this.state.from_date }),
            ...(this.state.to_date !== '' && { to_date: this.state.to_date }),
            ...(this.state.rx_group_associated !== '' && { rx_group_associated: this.state.rx_group_associated })
        }
        axios
            .post("/api/export-reports", filterData)
            .then(res => {
                this.setState({ loading: false })
                let sTable = "";
                sTable += "<table>";
                sTable += "<thead>";
                sTable += "<tr>";
                for (let column of this.columns) {
                    sTable += "<th>" + column.text + "</th>";
                }
                sTable += "</tr>";
                sTable += "</thead>";
                sTable += "<tbody>";
                for (let i in res.data.records) {
                    let record = res.data.records[i];
                    sTable += "<tr>";
                    for (let column of this.columns) {
                        if (column.cell && typeof column.cell === "function") {
                            sTable += "<td></td>";
                        } else if (record[column.key]) {
                            sTable += "<td>" + record[column.key] + "</td>";
                        } else {
                            sTable += "<td></td>";
                        }
                    }
                    sTable += "</tr>";
                }
                sTable += "</tbody>";
                sTable += "</table>";

                var style = "<style>";
                style = style + "table {width: 100%;font: 17px Calibri;}";
                style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
                style = style + "padding: 2px 3px;textAlign:left;}";
                style = style + "</style>";

                var win = window.open('', '_blank');
                win.document.write('<html><head>');
                win.document.write('<title>' + this.config.filename + '</title>');
                win.document.write(style);
                win.document.write('</head>');
                win.document.write('<body>');
                win.document.write('<h1>' + this.config.filename + '</h1>');
                win.document.write(sTable);
                win.document.write('</body></html>');
                win.print();
                win.close();
            })
    }

    openDatepicker = (e) => {
        if (e.target.id === "toD") {
            this.toDatePicker.setOpen(true);
        } else {
            this.fromDatePicker.setOpen(true);
        }
    }

    render() {
        let associatedRecords = []
        for (let i = 0; i < this.props.groups.length; i++) {
            let each = this.props.groups[i]
            if (each.rx_group_associated !== undefined) {
                associatedRecords = [...associatedRecords, ...(each.rx_group_associated.split(","))]
            }
        }
        let { rx_group_associated } = this.state
        return (
            <div className="d-flex reports" id="wrapper">
                {(this.props.auth.user.bonaRx || this.props.auth.user.superadmin) && (
                    <>
                        {this.state.loading &&
                            <div className="loading-wrapper"><PulseLoader color="#007bff" loading={true} size={20} /></div>}
                        {this.props.auth.user.superadmin &&
                            <CsvUploadModal getData={this.getData} />
                        }
                    </>
                )}
                <div id="page-content-wrapper">
                    {this.props.auth.user.superadmin || (this.props.auth.user.bonaRx && (this.props.auth.user.rx_group !== undefined && this.props.auth.user.rx_group !== "")) ? (
                        <div className="container-fluid">
                            {this.props.auth.user.superadmin &&
                                <button className="btn btn-outline-primary float-right mt-3 mr-2" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus} /> Upload CSV</button>
                            }
                            <h1 className="mt-2 text-primary">Detailed Report</h1>


                            <div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" defaultChecked={this.state.isFilterOpen} onClick={this.toggleChange} id="isFilterOpen" />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                        Show Filter
                                    </label>
                                </div>

                                <Collapse isOpened={this.state.isFilterOpen}>
                                    <form noValidate onSubmit={this.onSearch}>
                                        <div className="form-row" style={{ width: "1000px" }}>
                                            {(this.props.groups.length && this.props.auth.user.rx_group_associated !== undefined && this.props.auth.user.rx_group_associated !== "") ? (
                                                <div className="form-group col-md-3">
                                                    <label>Group</label>
                                                    <select className="form-control" onChange={this.onChangeSelect} id="rx_group_associated">
                                                        <option value="">All</option>
                                                        <option value={this.props.auth.user.rx_group} {...rx_group_associated === this.props.auth.user.rx_group && { "selected": true }}>{this.props.auth.user.name}--{this.props.auth.user.rx_group}</option>
                                                        {this.props.auth.user.rx_group_associated.split(",").map(each => {
                                                            let nameGroup = this.props.groups.find(i => i.rx_group === each);
                                                            return (
                                                                <option key={each} value={each} {...rx_group_associated === each && { "selected": true }}>{nameGroup ? nameGroup.name : ""}--{each}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>) : null}
                                            {this.props.auth.user.superadmin && this.props.groups.length ?
                                                (<div className="form-group col-md-3">
                                                    <label>Group</label>
                                                    <select className="form-control" onChange={this.onChangeSelect} id="rx_group_associated">
                                                        <option value="">All</option>
                                                        {this.props.groups.map(each => {
                                                            return (
                                                                <>{!associatedRecords.includes(each.rx_group) && each.rx_group !== "superadmin" && each.rx_group !== undefined && <option {...rx_group_associated === each.rx_group && { "selected": true }} key={each.rx_group} value={each.rx_group}>{each.name}-{each.rx_group}</option>}</>
                                                            )
                                                        })}
                                                    </select>
                                                </div>) : null}
                                            <div className="form-group col-md-3">
                                                <label htmlFor="prescription_npi">From Date</label>
                                                <DatePicker selected={this.state.from_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setFromDate(date)} className="form-control" ref={(c) => this.fromDatePicker = c} />
                                                <i aria-hidden="true" className="fa fa-calendar" id="fromD" onClick={this.openDatepicker}></i>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="mdeication_label">To Date</label>
                                                <DatePicker selected={this.state.to_date} placeholderText="MM/DD/YYYY" onChange={(date) => this.setToDate(date)} className="form-control" ref={(c) => this.toDatePicker = c} />
                                                <i aria-hidden="true" className="fa fa-calendar" id="toD" onClick={this.openDatepicker}></i>
                                            </div>

                                            <div className="form-group col-md-3">
                                                <label htmlFor="status">Status</label>
                                                <select className="form-control" onChange={this.onChangeSelect} id="status">
                                                    <option value="">All</option>
                                                    <option value="Reversed">Reversed</option>
                                                    <option value="Claim">Claim</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="prescription_npi">Prescriber NPI</label>
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.prescription_npi}
                                                    id="prescription_npi"
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="ndc">NDC</label>
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.ndc}
                                                    id="ndc"
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="mdeication_label">Medication Label</label>
                                                <input
                                                    onChange={this.onChange}
                                                    value={this.state.mdeication_label}
                                                    id="mdeication_label"
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>

                                            <div className="form-group col-md-3">
                                                <label htmlFor="type">Type</label>
                                                <select className="form-control" onChange={this.onChangeSelect} id="type">
                                                    <option value="">All</option>
                                                    <option value="Brand">Brand</option>
                                                    <option value="Generic">Generic</option>
                                                    {/* <option value="AR">Aberrent - AR</option> */}
                                                </select>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn btn-primary">Filter</button>
                                        <button type="reset" onClick={this.resetSearch} className="btn btn-secordry">Reset</button>

                                    </form>
                                </Collapse>
                            </div>

                            <br></br>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onChange={this.filter}
                                dynamic={true}
                                total_record={this.state.totalCount}
                                loading={true}
                                exportToCSV={this.exportToCSV}
                                exportToPDF={this.exportToPDF}
                            />
                        </div>
                    ) : (
                        <div className="container-fluid static-card">
                            <div className="card">
                                <div className="card-body">
                                    {(this.props.auth.user.rx_group === undefined || this.props.auth.user.rx_group === "") && this.props.auth.user.termCondition == 1 ? (
                                        <h6 class="font-weight-bold text-danger">You have no data to display. Your dispensing data will automatically be displayed when your custom cards are used at any pharmacy nationwide</h6>

                                    ) : (this.props.auth.user.rx_group === undefined || this.props.auth.user.rx_group === "") ? (
                                        <h6 class="font-weight-bold text-danger">To access all analytical tools you must register on BonaRx - FREE <br></br>
                                            <button className="btn btn-primary" data-toggle="modal" data-target="#term-condition">Register here</button></h6>
                                    ) : (
                                        <h6 class="font-weight-bold text-danger">You accounts is inactive!! To activate you account please <Link to="/support" className="card-body">Contact</Link> Us.</h6>
                                    )}
                                </div>
                            </div>
                            <img className="static-image" src={dashboard} />
                        </div>
                    )
                    }
                </div>
                <ToastContainer />
            </div>
        );
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records,
    groups: state.groups,
});

export default connect(
    mapStateToProps
)(Reports);
