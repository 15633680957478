import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import ReCAPTCHA from "react-google-recaptcha";
import psga from "../../utils/psga_new.png";
import Footer from "../partials/Footer";
import queryString from "query-string";
import isEmpty from "is-empty";
import $ from "jquery";
import { toast, ToastContainer } from "react-toastify";
import PulseLoader from "react-spinners/PulseLoader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

var x;
class Login extends Component {
  constructor() {
    super();
    this.state = {
      rx_group: "",
      password: "",
      email: "",
      phone: "",
      isFilterOpen: "email",
      errors: {},
      triggered: false,
      selected: "",
      section: "login",
      loading: false,
      type: "",
      otp: "",
      allowResend: true,
      isVisible: false,
    };
  }

  componentDidMount() {
    window.location.href = "https://tabula.psgarx.com/";
    if (this.props.auth.isAuthenticated) {
      //this.props.history.push("/dashboard");
    } else {
      localStorage.removeItem("jwtToken");
    }

    if (queryString.parse(window.location.search).multiple) {
      toast.warning("You have already logged in to other browser", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    $("body").css({ zoom: 1 });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated && !this.state.triggered) {
      this.setState(
        {
          triggered: true,
        },
        () => this.continue()
      );

      //this.props.history.push("/dashboard");
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  openModel = () => {
    $(".login-redirect").trigger("click");
  };

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleonChange(value) {
    x = value;
    console.log("Captcha value:", x);
  }
  toggleChange = (value) => {
    this.setState({
      isFilterOpen: value,
    });
  };

  onChangeRadio = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (x === undefined) {
      alert("please verify you are not robot");
    } else {
      let { errors } = this.state;
      if (this.state.email === "") {
        errors.email = "This field is required";
      } else {
        delete errors.email;
      }
      if (this.state.password === "") {
        errors.password = "This field is required";
      } else {
        delete errors.password;
      }
      if (isEmpty(errors)) {
        this.setState({ loading: true });
        const userData = {
          password: this.state.password,
        };
        if (this.state.isFilterOpen === "group") {
          userData.rx_group = this.state.rx_group.toUpperCase();
        } else {
          userData.email = this.state.email;
        }
        axios({
          method: "POST",
          url: "/api/login",
          data: userData,
        }).then((res) => {
          if (res.data.success) {
            this.setState({ section: "sendOtp", phone: res.data.phone });
          } else {
            this.setState({ errors: res.data });
          }
          this.setState({ loading: false });
        });
      } else {
        this.setState({ errors, loading: false });
      }

      // this.props.loginUser(userData);
    }
  };

  sendOtp = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.type === "") {
      errors.type = "This field is required";
    } else {
      delete errors.type;
    }
    if (isEmpty(errors)) {
      this.setState({ loading: true, allowResend: false });
      const userData = {
        //   email: "mhariri@smartsrx.com",
        email: this.state.email,
        type: this.state.type,
      };
      axios({
        method: "POST",
        url: "/api/send-otp",
        data: userData,
      }).then((res) => {
        if (res.data.success) {
          this.setState({ section: "verifyOtp", loading: false });
          $("#otp").focus();
          setTimeout(() => {
            this.setState({ allowResend: true });
          }, 10000);
        } else {
          confirmAlert({
            title: "Error",
            message: res.data.message,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.setState({ otp: "" });
                },
              },
              {
                label: "Cancel",
                onClick: () => {},
              },
            ],
          });
          this.setState({ loading: false });
        }
      });
    } else {
      this.setState({ errors, loading: false });
    }
  };

  verifyOtp = (e) => {
    e.preventDefault();
    let { errors } = this.state;
    if (this.state.otp === "") {
      errors.otp = "This field is required";
    } else {
      delete errors.otp;
    }
    if (isEmpty(errors)) {
      this.setState({ loading: true });
      const userData = {
        // email: "mhariri@smartsrx.com",
        email: this.state.email,
        otp: this.state.otp,
      };
      axios({
        method: "POST",
        url: "/api/verify-otp",
        data: userData,
      }).then((res) => {
        if (res.data.success) {
          this.props.loginUser(res.data.token);
        } else {
          this.setState({ loading: false });
          confirmAlert({
            title: "Error",
            message: res.data.message,
            buttons: [
              {
                label: "Ok",
                onClick: () => {
                  this.setState({ otp: "" });
                },
              },
              {
                label: "Cancel",
                onClick: () => {},
              },
            ],
          });
        }
      });
    } else {
      this.setState({ errors, loading: false });
    }
  };

  continue = () => {
    if (window.location.hostname !== "localhost") {
      if (this.props.auth.user.login_flow) {
        window.location.href =
          "http://tabula.psgarx.com?login_flow=true&reference=" +
          localStorage.getItem("jwtToken");
      } else {
        window.location.href =
          "http://tabula.psgarx.com?reference=" +
          localStorage.getItem("jwtToken");
      }
    } else {
      this.props.history.push("/landing");
    }
  };

  redirectClick = (e) => {
    this.setState({
      selected: e.currentTarget.id,
    });
  };

  resend = () => {
    this.setState({ section: "sendOtp" });
  };

  handlePassword = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  render() {
    const { errors, allowResend, isVisible } = this.state;
    const iFrame =
      '<div class="AuthorizeNetSeal"> <script type="text/javascript" language="javascript">var ANS_customer_id="308dab4c-21ef-414b-a2ca-db0d7684fa3d";</script> <script type="text/javascript" language="javascript" src="https://verify.authorize.net:443/anetseal/seal.js" ></script> </div>';
    return (
      <div
        className="nam login"
        style={{
          backgroundImage: `url(https://rxreachanalytis.s3.amazonaws.com/logo/RxConnexion_Login_Artwork.png)`,
        }}
        height={100}
      >
        {this.state.loading && (
          <div className="loading-wrapper">
            <PulseLoader color="#007bff" loading={true} size={20} />
          </div>
        )}
        <div className="container" style={{ minHeight: "calc(100vh - 85px)" }}>
          <div className="row">
            <div className="col-md-4 mx-auto mt-3 mb-3 card shadow-lg">
              {this.state.section === "login" ? (
                <div className="card-body p-1">
                  <h2
                    className="text-center text-primary mt-2 "
                    style={{ marginBottom: "10px" }}
                  >
                    <a href="https://www.psgarx.com/">
                      <img src={psga} height={50} />
                    </a>
                  </h2>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-email"
                      role="tabpanel"
                      aria-labelledby="pills-email-tab"
                    >
                      <form
                        noValidate
                        onSubmit={this.onSubmit}
                        className="white"
                      >
                        <label htmlFor="email">Email </label>
                        <input
                          onChange={this.onChange}
                          value={this.state.email}
                          error={errors.email}
                          id="email"
                          type="email"
                          className={classnames("form-control", {
                            invalid: errors.email,
                          })}
                        />
                        <span className="text-danger">{errors.email}</span>
                        <br />

                        <label htmlFor="password">Password</label>
                        <div className="password-wrapper">
                          <input
                            onChange={this.onChange}
                            value={this.state.password}
                            error={errors.password}
                            id="password"
                            type={isVisible ? "text" : "password"}
                            className={classnames("form-control", {
                              invalid: errors.password,
                            })}
                          />
                          <span
                            className="icon eye-icon"
                            onClick={this.handlePassword}
                          >
                            {isVisible ? (
                              <EyeOutlined />
                            ) : (
                              <EyeInvisibleOutlined />
                            )}
                          </span>
                          <span className="text-danger">{errors.password}</span>
                        </div>
                        <p className="text-center pb-0 mt-2 mb-0">
                          <ReCAPTCHA
                            sitekey="6Lf03WQdAAAAAFFVuRu8tD3Bk-wOhHO22Tyeotyx"
                            onChange={this.handleonChange}
                          />
                          <button
                            type="submit"
                            className="btn btn-large btn-primary mt-2 px-5"
                          >
                            Login
                          </button>
                          <br></br>
                          <Link to="/forgot">Forgot Password</Link>
                          <h6 className="pt-3">OR</h6>

                          <Link to="/register">
                            <button
                              type="submit"
                              className="btn btn-large btn-primary mt-2 px-5"
                            >
                              Create Account
                            </button>
                          </Link>
                          <br></br>
                          <iframe
                            style={{
                              height: "102px",
                              width: "108px",
                              marginTop: "10px",
                              borderWidth: 0,
                            }}
                            title={"badge"}
                            srcDoc={iFrame}
                          />
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              ) : this.state.section === "sendOtp" ? (
                <div className="card-body p-1">
                  <h2
                    className="text-center text-primary mt-2 "
                    style={{ marginBottom: "10px" }}
                  >
                    <a href="https://www.psgarx.com/">
                      <img src={psga} height={50} />
                    </a>
                  </h2>
                  <h3>OTP Verification</h3>
                  <h6>
                    To verify your identity, we need to send you an
                    authorization code to the email or phone on the record
                  </h6>
                  <form noValidate onSubmit={this.sendOtp} className="white">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        checked={this.state.type === "email"}
                        onChange={() => this.onChangeRadio("type", "email")}
                      />
                      <label
                        className="form-check-label"
                        for="type"
                        style={{ fontSize: "16px" }}
                      >
                        Email (
                        {this.state.email.replace(
                          /^(.)(.*)(.@.*)$/,
                          (_, a, b, c) => a + b.replace(/./g, "*") + c
                        )}
                        )
                      </label>
                    </div>
                    <br></br>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        checked={this.state.type === "phone"}
                        onChange={() => this.onChangeRadio("type", "phone")}
                      />
                      <label
                        className="form-check-label"
                        for="type"
                        style={{ fontSize: "16px" }}
                      >
                        Phone (********{this.state.phone})
                      </label>
                    </div>
                    {this.state.email === "mehrdadhairi@gmail.com" ? (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="type"
                          checked={this.state.type === "cole"}
                          onChange={() => this.onChangeRadio("type", "cole")}
                        />
                        <label
                          className="form-check-label"
                          for="type"
                          style={{ fontSize: "16px" }}
                        >
                          Phone (********317)
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    <p className="text-center pb-0 mt-2 mb-0">
                      <button
                        type="submit"
                        className="btn btn-large btn-primary mt-2 px-5"
                      >
                        Send OTP
                      </button>
                    </p>
                  </form>
                  <br></br>
                </div>
              ) : (
                <div className="card-body p-1">
                  <h2
                    className="text-center text-primary mt-2 "
                    style={{ marginBottom: "10px" }}
                  >
                    <a href="https://www.psgarx.com/">
                      <img src={psga} height={50} />
                    </a>
                  </h2>
                  <h3>OTP Verification</h3>
                  <h6></h6>
                  <form noValidate onSubmit={this.verifyOtp} className="white">
                    <label htmlFor="email">Enter OTP </label>
                    <input
                      onChange={this.onChange}
                      value={this.state.otp}
                      error={errors.otp}
                      id="otp"
                      type="otp"
                      className={classnames("form-control", {
                        invalid: errors.otp,
                      })}
                    />
                    <span className="text-danger">{errors.otp}</span>
                    {allowResend && (
                      <a href="#" className="float-right" onClick={this.resend}>
                        Resend OTP
                      </a>
                    )}
                    <br></br>
                    <p className="text-center pb-0 mt-2 mb-0">
                      <button
                        type="submit"
                        className="btn btn-large btn-primary mt-2 px-5"
                      >
                        Verify OTP
                      </button>
                    </p>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, { loginUser })(Login);
